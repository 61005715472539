import React, { useState } from "react";

const ReviewForm = ({ email, cardBackgroundColor, textColor }) => {
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(false); // Added useState for form visibility

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reviewData = {
      name,
      review,
      date: new Date().toISOString(),
      approved: false,
    };

    try {
      const response = await fetch(`https://your-lambda-function-url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, review: reviewData }),
      });

      if (response.ok) {
        setSuccessMessage("Review submitted successfully!");
        setName("");
        setReview("");
        setShowForm(false); // Hide form on successful submission
      } else {
        setErrorMessage("Coming Soon...")
       // throw new Error("Failed to submit review");
      }
    } catch (error) {
      setErrorMessage("Coming Soon...")
      //setErrorMessage(error.message);
    }
  };

  const handleToggleForm = () => {
    if (showForm) {
      // If the form is currently visible, clear the form fields when hiding the form
      setName("");
      setReview("");
    }
    setShowForm((prev) => !prev);
  };

  return (
    <div>
      <button className="btn btn-border-light btn-lg" onClick={handleToggleForm}>
        {showForm ? "Cancel Review" : "Leave A Review"}
      </button>
      <section style={{ textAlign: "center", padding: 10 }}>
        {showForm && (
          <form className="form-group" onSubmit={handleSubmit}>
            <div style={{ marginTop: 20 }}>
              <input
                type="text"
                id="name"
                placeholder="Your Name"
                value={name}
                className="form-control"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <textarea
                id="review"
                rows={5}
                style={{ resize: "none" }}
                value={review}
                placeholder="Your Review"
                className="form-control"
                onChange={(e) => setReview(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <button className="btn btn-lg" style={{backgroundColor:cardBackgroundColor, color:textColor}} type="submit">Submit</button>
            </div>
          </form>
        )}
        {successMessage && <p>{successMessage}</p>}
        {errorMessage && <p>{errorMessage}</p>}
      </section>
    </div>
  );
};

export default ReviewForm;
